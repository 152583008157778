import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialLoadingState, initialLoadingTrueState, LoadingData } from 'common/util/loading';
import emptyFunction from 'common/util/emptyFunction';
import {
  NewQuantSettlementForm,
  NewQuantSettlementFormState,
} from './newQuantSettlementType';

const initialState: NewQuantSettlementFormState = {
  pageLoading: initialLoadingTrueState,
  formLoading: initialLoadingState,
  form: {
    dateTime: null,
    amount: null,
  },
};

const newQuantSettlementSlice = createSlice({
  name: 'newQuantSettlementSlice',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: emptyFunction,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    submit: (state, _action: PayloadAction<NewQuantSettlementForm>) => state,
    setFormLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.formLoading = payload;
      return state;
    },
    setForm(state, { payload }: PayloadAction<NewQuantSettlementForm>) {
      state.form = payload;
      return state;
    },
  },
});

export const newQuantSettlementActions = newQuantSettlementSlice.actions;
export const newQuantSettlementReducer = newQuantSettlementSlice.reducer;
