import * as React from 'react';
import Card from 'common/ui/Card';
import { DateTime } from 'luxon';
import { formatCurrency } from 'common/util/currency';
import { formatDateTime } from 'common/util/date';
import clsx from 'clsx';
import { QuestionIcon } from '@primer/octicons-react';
import { InvestmentDetailTransaction } from './investmentDetailPageTypes';

type PayoutInfo = {
  payoutInfo: InvestmentDetailTransaction[],
  companyRate: number,
  customerRate: number,
  dividend: number,
  mgmtFee: number,
}
const DividendPayoutInfo: React.FC<PayoutInfo> = (payoutInfo) => {
  const {
    payoutInfo: payoutList,
    companyRate,
    customerRate,
    dividend,
    mgmtFee,
  } = payoutInfo;
  const feesNeeded = dividend + mgmtFee;

  const getPartyTake = (amount: number, type: string): number => {
    console.log(amount);
    if (type === 'assoc') {
      if (amount < 0) {
        return 0;
      }
      return amount * customerRate;
    }
    if (amount < 0) {
      return amount;
    }
    return amount * companyRate;
  };
  const getNetProfit = (startAmt: number, endAmt: number): number => {
    const differences = startAmt - endAmt;
    return differences - feesNeeded;
  };

  const getTakeColour = (startAmt: number, endAmt: number): string => {
    const differences = startAmt - endAmt;
    if (differences > feesNeeded) {
      return 'text-success';
    }
    return 'text-danger';
  };

  const totalNetProfit = payoutList.reduce(
    (profit, current) => profit + getNetProfit(current.beforeAmount, current.afterAmount),
    0,
  );

  const totalCompanyNet = payoutList.reduce(
    (profit, current) => {
      const net = getNetProfit(current.beforeAmount, current.afterAmount);
      // console.log(net)
      const prl = getPartyTake(net, 'company');
      console.log(prl);
      return profit + prl;
    },
    0,
  );

  const totalAssocNet = payoutList.reduce(
    (profit, current) => {
      const net = getNetProfit(current.beforeAmount, current.afterAmount);
      const prl = getPartyTake(net, 'assoc');
      return profit + prl;
    },
    0,
  );

  const totalPayout = payoutList.reduce(
    (amount, current) => amount + current.amount, 0,
  );

  const getProfitAvailable = (bA: number, aA: number): number => bA - aA;

  const totalProfitAvailable = payoutList.reduce(
    (profit, curRec) => {
      const profitAvailable = getProfitAvailable(curRec.beforeAmount, curRec.afterAmount);
      return profit + profitAvailable;
    },
    0,
  );
  return (
    <Card title="PartialPayout Info">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Date</th>
            <th>Payout Amount</th>
            <th>
              Profit Available
              <br />
              during Extraction
            </th>
            <th>
              Payout Amount
              <br />
              Needed
              <span
                className="pl-2"
                data-tooltip-id="formula-tooltip"
                data-tooltip-content={`Dividend + Mgmt Fee = ${dividend} + ${mgmtFee}`}
                data-tooltip-place="top"
              >
                <QuestionIcon />
              </span>
            </th>
            <th>Net Profit</th>
            <th>Company Take</th>
            <th>Associate Take</th>
          </tr>
        </thead>
        <tbody>
          {
            payoutList.map((item, index) => (
              <tr key={item.id}>
                <td>{formatDateTime(item.dateTime)}</td>
                <td>
                  {formatCurrency(-item.amount, 'two')}
                </td>
                <td>
                  {formatCurrency(getProfitAvailable(item.beforeAmount, item.afterAmount), 'two')}
                </td>

                <td>
                  {formatCurrency(dividend + mgmtFee, 'two')}
                </td>
                <td className={`font-weight-bold ${getTakeColour(item.beforeAmount, item.afterAmount)}`}>
                  {formatCurrency(getNetProfit(item.beforeAmount, item.afterAmount), 'two')}
                </td>
                <td className={`font-weight-bold ${getTakeColour(item.beforeAmount, item.afterAmount)}`}>
                  {
                      formatCurrency(getPartyTake(getNetProfit(item.beforeAmount, item.afterAmount), 'company'), 'two')
                    }
                </td>
                <td className={`font-weight-bold ${getTakeColour(item.beforeAmount, item.afterAmount)}`}>
                  {
                      formatCurrency(getPartyTake(getNetProfit(item.beforeAmount, item.afterAmount), 'assoc'), 'two')
                    }
                </td>
              </tr>
            ))
          }
        </tbody>
        <tfoot>
          <tr>
            <td>Total</td>
            <td
              className={clsx('font-weight-bold')}
            >
              {formatCurrency(-totalPayout, 'two')}
            </td>
            <td
              className={clsx('font-weight-bold')}
            >
              {formatCurrency(totalProfitAvailable, 'two')}
            </td>
            <td
              className={clsx('font-weight-bold')}
            >
              {formatCurrency((dividend + mgmtFee) * payoutList.length, 'two')}
            </td>
            <td
              className={clsx('font-weight-bold', totalNetProfit > 0 && 'text-success', totalNetProfit < 0 && 'text-danger')}
            >
              {formatCurrency(totalNetProfit, 'two')}
            </td>
            <td
              className={clsx('font-weight-bold', totalCompanyNet > 0 && 'text-success', totalCompanyNet < 0 && 'text-danger')}
            >
              {formatCurrency(totalCompanyNet, 'two')}
            </td>
            <td
              className={clsx('font-weight-bold', totalAssocNet > 0 && 'text-success', totalAssocNet < 0 && 'text-danger')}
            >
              {formatCurrency(totalAssocNet, 'two')}
            </td>
          </tr>
        </tfoot>
      </table>
    </Card>
  );
};

export default DividendPayoutInfo;
