import { ISagaModule } from 'redux-dynamic-modules-saga';
import futureDetailSaga from './settlementPageSaga';
import { settlementsReducer } from './settlementsPageSlice';
import { SettlementPageReduxState } from './settlementPageTypes';

export default (): ISagaModule<SettlementPageReduxState> => ({
  id: 'futureDetail',
  reducerMap: {
    settlementDetails: settlementsReducer,
  },
  sagas: [futureDetailSaga],
  retained: true,
});
