import * as React from 'react';
import queryString from 'query-string';

import logo from 'assets/images/logo.png';
import facebook from 'assets/images/facebook.png';
import appConfig from 'common/setup/config';
import { FormikForm, FormikTextField } from 'common/form';
import { useDispatch, useSelector } from 'react-redux';
import classes from './signInPage.module.scss';
import { SignInPageReduxState } from './signInPageTypes';
import { signInActions } from './signInPageSlice';

const SignInPage: React.FC = () => {
  const dispatch = useDispatch();
  const formLoading = useSelector(
    (s: SignInPageReduxState) => s.signIn.formLoading,
  );
  const form = useSelector(
    (s: SignInPageReduxState) => s.signIn.form,
  );
  const errorMessage = form.errorMsg;
  console.log(errorMessage);

  const onSubmit = React.useCallback((f) => {
    // console.log(f)
    console.log(dispatch(signInActions.submit(f)));
  }, [dispatch]);

  return (
    <div className="container h-100">
      <div className="row vh-100 justify-content-center align-items-center">
        <div className="col-12 col-md-10 col-lg-6">
          <div className="card w-100">
            <div className="card-body">
              <img src={logo} className={classes.logo} alt="Logo" />
              <div className="m-4" />
              <h4 className="display-5 text-center mb-3">Return Tracking System</h4>

              <div className="row">
                <div className="col-12">

                  {errorMessage && (
                  <div className="alert alert-danger">{errorMessage}</div>
                  )}

                  <FormikForm
                    initialValues={form}
                    onSubmit={onSubmit}
                  >

                    {({ values, setFieldValue, handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                        <FormikTextField
                          label="Username"
                          placeholder="If you don't have one, get one from current devs"
                          name={nameof(values.username)}
                        />

                        <FormikTextField
                          label="Password"
                          placeholder="If you forgot yours, ask devs to reset for you"
                          name={nameof(values.password)}
                          type="password"
                        />
                        <button type="submit" className="btn btn-primary btn-block">
                          Login
                        </button>
                      </form>
                    )}
                  </FormikForm>
                </div>
              </div>
              <h2 className="text-center my-4">OR</h2>
              <a
                href={`${appConfig.apiUrl}/auth/login`}
                className={`btn ${classes['btn-facebook']} text-center`}
              >
                <img src={facebook} alt="" />
                Login with Facebook
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
