// eslint-disable-next-line import/prefer-default-export
export const formatCurrency = (money: number|string, decimalMode: false | 'two' | 'three' | 'four' | 'six' = false): string => {
  let duit = money;
  if (typeof money === 'string') {
    duit = parseFloat(money);
  }
  // console.log(money.toLocaleString("en", {
  //   minimumFractionDigits: 2,
  //   maximumFractionDigits: 2,
  // }));
  let decimal = 0;
  let minDecimal = 0;
  if (decimalMode === 'two') {
    decimal = 2;
    minDecimal = 2;
  } if (decimalMode === 'three') {
    decimal = 3;
    minDecimal = 2;
  } if (decimalMode === 'four') {
    decimal = 4;
    minDecimal = 4;
  } if (decimalMode === 'six') {
    decimal = 6;
    minDecimal = 6;
  }

  const moneyString = duit.toLocaleString('en', {
    minimumFractionDigits: minDecimal,
    maximumFractionDigits: decimal,
  });

  return `${moneyString}`;
};
