import {
  put, takeLatest,
} from 'redux-saga/effects';
import { bindLoadingActions } from 'common/util/loading';
import { call, select } from 'typed-redux-saga';
import { Action } from '@reduxjs/toolkit';
import ApiService from 'common/api';
import { IdLabelLookup } from 'common/util/lookup';
import history from 'common/setup/history';
import produce from 'immer';
import toastActions from 'common/ui/Toast/toastActions';
import { createInvestmentActions } from './createInvestmentPageSlice';
import { CreateInvestmentForm, CreateInvestmentPageReduxState } from './createInvestmentPageTypes';
import { CreateCustomerPageReduxState } from '../../customer/createCustomer/createCustomerPageTypes';
import { mapCreateInvestmentToApi } from './createInvestmentPageMapper';

function* onInit(action: Action): Generator {
  if (!createInvestmentActions.init.match(action)) { return; }

  let newForm: CreateInvestmentForm|null = null;
  const formCache = yield* select(
    (s: CreateInvestmentPageReduxState) => s.createInvestment.formCache,
  );
  if (formCache !== null) {
    // Try to get customer create state if any customer is created. If yes it means this page is
    // coming back from there.
    const customerLoading = yield* select(
      (s: CreateCustomerPageReduxState) => s.createCustomer?.formLoading,
    );

    if (customerLoading?.isSuccess) {
      newForm = produce(formCache, (draft) => {
        if (typeof customerLoading?.data === 'number') {
          draft.customerId = customerLoading.data;
        }
      });
    }
    yield put(createInvestmentActions.setFormCache(null));
  }

  yield put(createInvestmentActions.setInitialState());

  if (newForm) {
    yield put(createInvestmentActions.setForm(newForm));
  }

  const [, loadingFail, loadingSuccess] = bindLoadingActions(
    createInvestmentActions.setPageLoading,
  );

  try {
    const customers = yield* call(ApiService.get<IdLabelLookup[]>(), '/v1/customers/lookup');
    yield put(createInvestmentActions.setCustomers(customers));

    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

function* onCreateCustomer(action: Action): Generator {
  if (!createInvestmentActions.createNewCustomer.match(action)) { return; }

  yield put(createInvestmentActions.setFormCache(action.payload));
  history.push('/member/customer/create');
}

function* onSubmit(action: Action): Generator {
  if (!createInvestmentActions.submit.match(action)) { return; }

  const [loadingStart, loadingFail, loadingSuccess] = bindLoadingActions(
    createInvestmentActions.setFormLoading,
  );

  try {
    yield put(loadingStart());

    const form = mapCreateInvestmentToApi(action.payload);
    const result = yield* call(ApiService.post<{ id: number }>(), '/v1/investments', form);
    yield put(loadingSuccess());

    toastActions.success('Investment is added successfully.');
    history.push(`/member/investment/detail/${result.id}`);
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

export default function* mainSaga(): Generator {
  yield takeLatest(createInvestmentActions.init.type, onInit);
  yield takeLatest(createInvestmentActions.createNewCustomer.type, onCreateCustomer);
  yield takeLatest(createInvestmentActions.submit.type, onSubmit);
}
