import * as React from 'react';
import Modal, { ModalPromptButton } from 'common/ui/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { FormikDatePicker, FormikForm, FormikNumberField } from 'common/form';
import { LoadingAlert } from 'common/ui/Alert';
import FormValue from 'common/ui/FormValue';
import { formatCurrency } from 'common/util/currency';
import { QuestionIcon } from '@primer/octicons-react';
import {
  dateValidator, numberValidator, stringValidator, Validate,
} from 'common/form/validations';
import { Tooltip } from 'react-tooltip';
import { investmentDetailActions } from './investmentDetailPageSlice';
import { InvestmentDetailPageReduxState, InvestmentPartialPayoutForm } from './investmentDetailPageTypes';

interface TakeSummary {
  companyTake: number,
  assocTake: number,
}

interface TakeDataNeeded {
  profitAvailable: number,
  dividendNeed: number,
  rate: number,
  type: string,
}

const initialForm: InvestmentPartialPayoutForm = {
  id: 0,
  amount: null,
  percent: null,
  transactionDateTime: null,
  customerAmount: null,
  companyAmount: null,
};
const takeSummary: TakeSummary = {
  companyTake: 0,
  assocTake: 0,
};
const DividendPayoutModal: React.FC = () => {
  const calculateTake = ({
    profitAvailable, dividendNeed, rate, type,
  }: TakeDataNeeded): number => {
    if (profitAvailable > dividendNeed) {
      return (profitAvailable - dividendNeed) * rate;
    }
    if (type === 'assoc') {
      return 0;
    }
    return profitAvailable - dividendNeed;
  };

  const dispatch = useDispatch();
  const loading = useSelector(
    (s: InvestmentDetailPageReduxState) => s.investmentDetail.partialPaymentLoading,
  );
  const prompt = useSelector(
    (s: InvestmentDetailPageReduxState) => s.investmentDetail.partialPaymentPrompt,
  );
  const fullData = useSelector(
    (s: InvestmentDetailPageReduxState) => s.investmentDetail.detail,
  );

  const annualDividendPerc: number = fullData.annualPayoutPerc / 200; // ( / 100 / 2)

  const annualMgmtFeePerc: number = fullData.mgmtFeePerc / 200;
  const showingPerc = formatCurrency((annualDividendPerc + annualMgmtFeePerc) * 100, 'two');

  const payoutDividend = fullData.originalAmount * annualDividendPerc;
  const payoutMgmt = fullData.originalAmount * annualMgmtFeePerc;

  const dividend = payoutDividend + payoutMgmt;

  const payableAmount = useSelector(
    (s: InvestmentDetailPageReduxState) => s.investmentDetail.partialPayoutAmount,
  );

  const profitAvailable = useSelector(
    (s: InvestmentDetailPageReduxState) => s.investmentDetail.profitAvailable,
  );

  // console.log(`Profit Available:${profitAvailable}`);
  const [promptForm, setPromptForm] = React.useState(initialForm);
  const [formTakeSummary, setFormTakeSummary] = React.useState(takeSummary);

  const { transactions } = fullData;

  const validateForm: Validate<InvestmentPartialPayoutForm> = ({ form, validator }) => {
    validator
      .validateField(nameof(form.amount),
        stringValidator.required(),
        numberValidator.gt(0))
      .validateField(nameof(form.transactionDateTime), dateValidator.required());

    return validator;
  };

  React.useEffect(() => {
    if (loading.isSuccess) {
      dispatch(investmentDetailActions.setPartialPaymentPrompt());
    }
  }, [loading]);

  React.useEffect(() => {
    if (prompt.id) {
      const defaultPercent = 100;
      setPromptForm({
        ...promptForm,
        id: prompt.id,
        amount: payableAmount,
      });
      setFormTakeSummary({
        companyTake: calculateTake({
          profitAvailable,
          dividendNeed: dividend,
          rate: fullData.companyTake / 100,
          type: 'company',
        }),
        assocTake: calculateTake({
          profitAvailable,
          dividendNeed: dividend,
          rate: fullData.customerTake / 100,
          type: 'assoc',
        }),
      });
    }
  }, [prompt]);

  React.useEffect(() => {
    // console.log(payableAmount)
    setPromptForm(
      {
        ...promptForm,
        amount: payableAmount,
        customerAmount: 0,
        companyAmount: 0,
      },
    );
  }, [payableAmount]);
  React.useEffect(() => {
    setFormTakeSummary({
      companyTake: calculateTake({
        profitAvailable,
        dividendNeed: dividend,
        rate: fullData.companyTake / 100,
        type: 'company',
      }),
      assocTake: calculateTake({
        profitAvailable,
        dividendNeed: dividend,
        rate: fullData.customerTake / 100,
        type: 'assoc',
      }),
    });
  }, [profitAvailable]);

  return (
    <Modal
      size="default"
      isOpen={prompt.fundType === 'fpm'}
    >
      <h3>Dividend Extraction</h3>
      <FormikForm
        initialValues={promptForm}
        enableReinitialize
        validate={validateForm}
        onSubmit={(f) => {
          dispatch(investmentDetailActions.submitPartialPayment(f));
          setPromptForm({
            ...promptForm,
            transactionDateTime: null,
          });
        }}
      >
        {({
          values, handleSubmit, setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <LoadingAlert loading={loading} />
            <FormValue
              label="Form No:"
              value={prompt.name}
            />
            <div className="row">
              <div className="col-6">
                <FormValue
                  label="Current Pool Amount:"
                  value={`${formatCurrency(prompt.amount)} USDT`}
                />
              </div>
              <div className="col-6">
                <FormValue
                  label="Invested Amount:"
                  value={`${formatCurrency(prompt.originalAmount)} USDT`}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <FormValue
                  label="Annual Dividend:"
                  value={`${formatCurrency(fullData.annualPayoutPerc, 'two')}%`}
                />
              </div>
              <div className="col-6">
                <FormValue
                  label="Annual Management Fee:"
                  value={`${formatCurrency(fullData.mgmtFeePerc, 'two')}%`}
                />
              </div>
              <div className="col-12">
                <div className="alert alert-info text-center">
                  <p>
                    Profit in the Pool
                  </p>
                  <h4 className="">{`${formatCurrency(profitAvailable.toFixed(2), 'three')} USDT`}</h4>
                </div>
              </div>
            </div>

            <FormikDatePicker
              time
              name={nameof(values.transactionDateTime)}
              label="Transaction Date Time"
              onChange={(selectedDate) => {
                dispatch(investmentDetailActions
                  .setFPMAvailablePayoutByDate({
                    selectedDate,
                    transactions,
                    dividendNeeded: dividend,
                  }));
                setPromptForm({
                  ...promptForm,
                  transactionDateTime: selectedDate,
                });
              }}
            />
            <FormikNumberField
              name={nameof(values.amount)}
              decimal={2}
              inputPostLabel="USDT"
              label="Payout Amount"
              onBlur={(newValue) => {
                setFormTakeSummary({
                  companyTake: calculateTake({
                    profitAvailable: newValue ?? 0,
                    dividendNeed: dividend,
                    rate: fullData.companyTake / 100,
                    type: 'company',
                  }),
                  assocTake: calculateTake({
                    profitAvailable: newValue ?? 0,
                    dividendNeed: dividend,
                    rate: fullData.customerTake / 100,
                    type: 'assoc',
                  }),
                });
              }}
            />
            <div className="row">
              <div className="col-6">
                <div className="alert alert-success text-center">
                  <p>
                    Customer Take
                    <span
                      className="pl-2"
                      data-tooltip-id="formula-tooltip"
                      data-tooltip-content={`Dividend, Annual Percentage / 2 (${formatCurrency(fullData.annualPayoutPerc / 2, 'two')})`}
                      data-tooltip-place="top"
                    >
                      <QuestionIcon />
                    </span>
                  </p>
                  <h4 className="">{`${formatCurrency(payoutDividend.toFixed(2), 'three')} USDT`}</h4>
                </div>
              </div>
              <div className="col-6">
                <div className="alert alert-success text-center">
                  <p>
                    Management Fee
                    <span
                      className="pl-2"
                      data-tooltip-id="formula-tooltip"
                      data-tooltip-content={`Management Percentage / 2 (${formatCurrency(fullData.mgmtFeePerc / 2, 'two')})`}
                      data-tooltip-place="top"
                    >
                      <QuestionIcon />
                    </span>
                  </p>
                  <h4 className="">{`${formatCurrency(payoutMgmt.toFixed(2), 'three')} USDT`}</h4>
                </div>
              </div>
              <div className="col-6">
                <div className="alert alert-success text-center">
                  <p>
                    Company Take
                    {' '}
                    {formatCurrency(fullData.companyTake)}
                    %
                    <span
                      className="pl-2"
                      data-tooltip-id="formula-tooltip"
                      data-tooltip-content="If it is negative, company is facing losts"
                      data-tooltip-place="top"
                    >
                      <QuestionIcon />
                    </span>
                  </p>
                  <h4 className="">{`${formatCurrency(formTakeSummary.companyTake.toFixed(2), 'three')} USDT`}</h4>
                </div>

              </div>
              <div className="col-6">
                <div className="alert alert-success text-center">
                  <p>
                    Associate Take
                    {' '}
                    {formatCurrency(fullData.customerTake)}
                    %
                    <span
                      className="pl-2"
                      data-tooltip-id="formula-tooltip"
                      data-tooltip-content="Associate Take, 0 if dividend required is more than payout available"
                      data-tooltip-place="top"
                    >
                      <QuestionIcon />
                    </span>
                  </p>
                  <h4 className="">{`${formatCurrency(formTakeSummary.assocTake.toFixed(2), 'three')} USDT`}</h4>
                </div>
              </div>
            </div>
            <ModalPromptButton
              isLoading={loading.isLoading}
              yesLabel="Confirm"
              noLabel="Cancel"
              onYesClick={handleSubmit}
              onNoClick={() => dispatch(investmentDetailActions.setPartialPaymentPrompt())}
            />
          </form>
        )}
      </FormikForm>
      <Tooltip id="formula-tooltip" />
    </Modal>
  );
};

export default DividendPayoutModal;
