import {
  put, takeLatest,
} from 'redux-saga/effects';
import { bindLoadingActions } from 'common/util/loading';
import { call } from 'typed-redux-saga';
import ApiService from 'common/api';
import { Action } from 'redux';
import toastActions from 'common/ui/Toast/toastActions';
import history from 'common/setup/history';
import { toApiDate, toApiDateTime } from 'common/util/date';
import { closeInvestmentActions } from './closeInvestmentPageSlice';
import { eguyCloseSummaryActions as actions } from './eguyCloseInvestmentSlice';
import { EguyCloseSummaryRemote, InvestmentCloseSummaryRemote } from './closeInvestmentPageTypes';
import { mapCloseInvestmentSummaryFromApi, mapEguySummaryFromApi } from './closeInvestmentPageMapper';

function* onInit(action: Action): Generator {
  if (!actions.init.match(action)) { return; }

  yield put(actions.setInitialState());

  const [, loadingFail, loadingSuccess] = bindLoadingActions(actions.setPageLoading);
  try {
    const resp = yield* call(ApiService.get<EguyCloseSummaryRemote>(), `/eguy/investments/${action.payload}/close_summary`);
    const result = mapEguySummaryFromApi(resp);
    yield put(actions.setDetail(result));
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

function* onSubmit(action: Action): Generator {
  if (!closeInvestmentActions.submit.match(action)) { return; }

  const form = action.payload;

  const [loadingStart, loadingFail, loadingSuccess] = bindLoadingActions(
    closeInvestmentActions.setFormLoading,
  );

  try {
    yield put(loadingStart());

    yield* call(ApiService.post<{ id: number }>(), '/v1/investments/close', {
      id: form.id,
      reinvest_amount: form.reinvestAmount,
      transaction_hash: form.transactionHash,
      wallet_address: form.walletAddress,
      exchange_network: form.exchangeNetwork,
      transaction_date_time: toApiDateTime(form.effectiveDate),
      referral_fee: form.referralFee,
      referral_dj_fee: form.referralDjFee,
      referral_transaction_date_time: toApiDateTime(form.referralTransactionDateTime),
      referral_exchange_network: form.referralExchangeNetwork,
      referral_wallet_address: form.referralWalletAddress,
      referral_transaction_hash: form.referralTransactionHash,
    });
    yield put(loadingSuccess());

    toastActions.success('Investment is closed successfully.');
    history.push('/member/investment/list');
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

function* closeAccount(action: Action) : Generator {
  if (!actions.submit.match(action)) { return; }

  const form = action.payload;

  const [loadingStart, loadingFail, loadingSuccess] = bindLoadingActions(
    actions.setPageLoading,
  );

  try {
    console.log(form);
    yield* call(ApiService.post<{ id: number }>(), '/eguy/investments/close', {
      id: form.id,
      reinvest_amount: form.reinvestAmount ?? 0,
      transaction_date_time: toApiDateTime(form.effectiveDate),
    });
    yield put(loadingSuccess());

    toastActions.success('Investment is closed successfully.');
    history.push(`/member/investment/detail/${form.id}`);
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

export default function* mainSaga(): Generator {
  yield takeLatest(actions.init.type, onInit);
  yield takeLatest(actions.submit.type, closeAccount);
}
