import { ISagaModule } from 'redux-dynamic-modules-saga';
import newQuantSettlementPageSaga from './newQuantSettlementPageSaga';
import { newQuantSettlementReducer } from './newQuantSettlementSlice';
import { NewQuantSettlementPageReduxState } from './newQuantSettlementType';

export default (): ISagaModule<NewQuantSettlementPageReduxState> => ({
  id: 'newQuantSettlementSlice',
  reducerMap: {
    newQuantSettlement: newQuantSettlementReducer,
  },
  sagas: [newQuantSettlementPageSaga],
  retained: true,
});
