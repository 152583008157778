import { ISagaModule } from 'redux-dynamic-modules-saga';
import closeInvestmentSaga from './closeInvestmentPageSaga';
import { closeInvestmentReducer } from './closeInvestmentPageSlice';
import {
  CloseInvestmentPageReduxState,
  EguyCloseSummaryPageState,
  EguyCloseSummaryReduxPageState,
} from './closeInvestmentPageTypes';
import { eguyCloseSummaryReducer } from './eguyCloseInvestmentSlice';

// export default (): ISagaModule<EguyCloseSummaryReduxPageState> => ({
export default (): ISagaModule<EguyCloseSummaryReduxPageState> => ({
  id: 'eguySummary',
  reducerMap: {
    // closeInvestment: closeInvestmentReducer,
    eguySummary: eguyCloseSummaryReducer,
  },
  sagas: [closeInvestmentSaga],
  retained: true,
});
