import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import appConfig from 'common/setup/config';
import { getUserCredentials, updateUserCredentials, dispatchLogout } from './credentialMiddleware';

const baseUrl = appConfig.apiUrl;

export enum ApiErrorEnum {
  server, network, request
}

export class ApiError extends Error {
  // Reason may be one of the following:
  // server  - Server returned 5xx.
  // network - Respond not received due to network issue.
  // request - Server does not accept the request,
  //           most probably due to bad client request. (Failed validation etc.)

  reason: ApiErrorEnum;

  data: unknown;

  // Make sure that message is always human readable and does not contain any
  // sensitive information. Because there is chance the message would be appear on screen.
  constructor(message: string, reason: ApiErrorEnum, data?: unknown) {
    super(message);
    this.reason = reason;
    this.data = data;
  }
}

const getCommonHeaders = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${getUserCredentials().accessToken}`,
});

const genericError = 'Sorry, something went wrong while processing your request.';

interface ISimulateSendParam<T> {
  successRate?: number;
  delay?: number;
  response?: T;
}

const simulateSend = <T = void>() => (data?: unknown, options?: ISimulateSendParam<T>)
  : Promise<T> => {
  // successRate: The chance the simulation would be successful.
  // delay: Time to wait for response.
  const { successRate = 0.5, delay = 1000, response = undefined } = options || {};

  // eslint-disable-next-line no-console
  console.log('Simulating API Call with payload:', data);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (Math.random() <= successRate) {
        resolve(response as T);
      } else {
        reject(new ApiError(genericError, ApiErrorEnum.network, 'Simulating API failure.'));
      }
    }, delay);
  });
};

interface ICommandResult<T = Record<string, Array<string>>> {
  success: boolean;
  data: T;
}

interface IAxiosInnerResult<T> {
  response: AxiosResponse<ICommandResult<T>>|null;
  error: ApiError|null;
  isUnauthorized: boolean;
}

const callAxiosInner = async <T>(axiosOptions: AxiosRequestConfig) => {
  const result: IAxiosInnerResult<T> = {
    response: null,
    error: null,
    isUnauthorized: false,
  };

  let axiosResult = null;
  try {
    axiosResult = await axios.request<ICommandResult<T>>(axiosOptions);
    result.response = axiosResult;
  } catch (error) {
    if (error.response) {
      console.log(error);
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status.toString()[0] === '5') {
        result.error = new ApiError(genericError, ApiErrorEnum.server, error.response.data);
      } if (error.response.status === 401) {
        result.isUnauthorized = true;
      }

      // For now consider 4xx response (other than 401) as valid and let the response checker
      // to determine the outcome.
      result.response = error.response;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser
      result.error = new ApiError(genericError, ApiErrorEnum.network, error);
    } else {
      // Something happened in setting up the request that triggered an Error
      result.error = new ApiError(genericError, ApiErrorEnum.request, error);
    }
  }

  return result;
};

interface IApiCallOptions<T> extends AxiosRequestConfig {
  mapResolve?: <TResp = Record<string, unknown>>
    (data: TResp, headers: Record<string, unknown>) => T;
  headers?: Record<string, unknown>;
}

const call = async <T>(options: IApiCallOptions<T>): Promise<T> => {
  const { mapResolve = null, headers: requestHeaders = {} } = options;

  let isFulfilled = true;
  let result = await callAxiosInner<T>({
    ...options,
    headers: {
      ...getCommonHeaders(),
      ...requestHeaders,
    },
  });

  if (result.isUnauthorized) {
    // API returns unauthorized, we need a new token.
    isFulfilled = false;
  } else if (result.error) {
    // If there is any error from axios, throw it straight away.
    throw result.error;
  }

  if (!isFulfilled) {
    const refreshTokenResult = await callAxiosInner<string>({
      method: 'post',
      url: `${baseUrl}/auth/refresh`,
      headers: {
        ...getCommonHeaders(),
      },
      data: { refresh_token: getUserCredentials().refreshToken },
    });

    if (refreshTokenResult.response?.data.success === true) {
      updateUserCredentials(refreshTokenResult.response.data.data);
    }

    result = await callAxiosInner<T>({
      ...options,
      headers: {
        ...getCommonHeaders(),
        ...requestHeaders,
      },
    });
  }

  if (result.isUnauthorized) {
    // API still returns unauthorized, log the user out.
    dispatchLogout();
  } if (result.error) {
    // If there is any error from axios, throw it straight away.
    throw result.error;
  }

  // Do some basic parsing on the result response.
  // Refer to the docs for the format specification.
  // - Check data.success is false.
  // - Grab the message from data if not successful.

  // Has to cast the response to match the command result properties.
  const responseData = result.response?.data as unknown as ICommandResult|undefined;
  if (responseData && responseData.success === false) {
    let errorMessage = genericError;

    const errorData = responseData?.data;
    if (typeof errorData === 'string') {
      errorMessage = errorData;
    } else if (Array.isArray(errorData)) {
      errorMessage = errorData.length > 0
        ? errorData[0]
        : errorMessage;
    }

    throw new ApiError(errorMessage, ApiErrorEnum.request, result.response);
  }

  if (mapResolve) {
    return mapResolve(result.response?.data, result.response?.headers);
  }

  // At this point it is confirmed the response would not be null (unless back end return null)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return result.response!.data.data;
};

const get = <T>() => async (url: string): Promise<T> => call<T>({
  method: 'get',
  url: baseUrl + url,
});

const getAbsolute = <T>() => async (url: string): Promise<T> => call<T>({
  method: 'get',
  url,
});

const del = <T = void>() => async (
  url: string,
): Promise<T> => call<T>({
  method: 'delete',
  url: baseUrl + url,
});

const post = <T = void>() => async (
  url: string,
  data: unknown,
  { mapResolve }: IApiCallOptions<T> = {},
): Promise<T> => call<T>({
  method: 'post',
  url: baseUrl + url,
  data,
  mapResolve,
});

const put = <T = void>() => async (
  url: string,
  data: unknown,
  { mapResolve, headers = {} }: IApiCallOptions<T> = {},
): Promise<T> => call<T>({
  method: 'put',
  data,
  url: baseUrl + url,
  mapResolve,
  headers,
});

export default {
  get,
  getAbsolute,
  post,
  put,
  delete: del,
  simulateSend,
};
