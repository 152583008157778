import {
  put, takeLatest,
} from 'redux-saga/effects';
import { bindLoadingActions } from 'common/util/loading';
import { Action } from 'redux';
import { call, SagaGenerator, select } from 'typed-redux-saga';
import ApiService from 'common/api';
import { getTradePrices } from 'context/trade/tradeSaga';
import toastActions from 'common/ui/Toast/toastActions';
import { toApiDateTime } from 'common/util/date';
import { investmentDetailActions } from './investmentDetailPageSlice';
import { mapInvestmentDetailFromApi, mapInvestmentDetailSpotBalanceFromApi } from './investmentDetailPageMapper';
import { InvestmentDetailRemote } from '../shared/investmentDetailRemote';
import { InvestmentDetailPageReduxState, InvestmentPartialPayoutForm } from './investmentDetailPageTypes';

function* onInit(action: Action): Generator {
  if (!investmentDetailActions.init.match(action)) { return; }

  yield put(investmentDetailActions.setInitialState());

  const [, loadingFail, loadingSuccess] = bindLoadingActions(
    investmentDetailActions.setPageLoading,
  );
  try {
    const resp = yield* call(ApiService.get<InvestmentDetailRemote>(), `/v1/investments/${action.payload}`);
    const result = mapInvestmentDetailFromApi(resp);

    // Collect trade ids
    // const tradeSymbols = [...new Set(result.spotTrades.map((t) => t.coinGeckoId))];
    // const prices = tradeSymbols.length > 0
    //   ? yield* call(getTradePrices, tradeSymbols)
    //   : {};
    // const spotResult = mapInvestmentDetailSpotBalanceFromApi(resp, prices);
    const netProfit = result.transactions.filter((item) => item.type === 'settlements' || item.type === 'partial_payout').reduce(
      (totalProfit, currentProfit) => totalProfit + currentProfit.amount, 0,
    );
    if (result.fundType === 'cpm') {
      yield put(investmentDetailActions.setAvailablePayout(netProfit));
    }

    if (result.fundType === 'fpm') {
      const feesPerc = (Number(result.annualPayoutPerc) + Number(result.mgmtFeePerc)) / 200;
      const dividends = result.originalAmount * feesPerc;
      const extraAvailable = result.currentAmount - result.originalAmount;
      if (extraAvailable <= 0) {
        yield put(investmentDetailActions.setAvailablePayout(dividends));
        yield put(investmentDetailActions.setNetProfit(0));
      } else if (extraAvailable < dividends) {
        yield put(investmentDetailActions.setAvailablePayout(dividends));
        yield put(investmentDetailActions.setNetProfit(netProfit));
      } else {
        yield put(investmentDetailActions.setAvailablePayout(extraAvailable));
        yield put(investmentDetailActions.setNetProfit(extraAvailable));
      }
    }
    yield put(investmentDetailActions.setDetail(result));
    // yield put(investmentDetailActions.setSpotBalance(spotResult));
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

function* onConfirmPendingSettlement() : Generator {
  const [loadingStart, loadingFail] = bindLoadingActions(
    investmentDetailActions.setPendingSettlementLoading,
  );
  const { id } = yield* select((s: InvestmentDetailPageReduxState) => s.investmentDetail
    .pendingSettlementPrompt);
  yield put(loadingStart());

  try {
    yield* call(ApiService.post(), '/v1/investments/pending_settlement', {
      id,
    });

    toastActions.success('The investment is set as pending settlement.');
    yield put(investmentDetailActions.init(id));
  } catch (e) {
    toastActions.error(e.message);
    yield put(loadingFail(e.message));
  }
}

function* onSubmitPartialPayment(action: Action) : Generator {
  if (!investmentDetailActions.submitPartialPayment.match(action)) { return; }

  const [loadingStart, loadingFail] = bindLoadingActions(
    investmentDetailActions.setPartialPaymentLoading,
  );
  const form = action.payload;
  yield put(loadingStart());

  try {
    yield* call(ApiService.post(), '/v1/investments/partial_payout', {
      id: form.id,
      amount: form.amount,
      transaction_date_time: toApiDateTime(form.transactionDateTime),
    });

    toastActions.success('Partial payout is completed on this investment.');
    yield put(investmentDetailActions.init(form.id));
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

export default function* mainSaga(): Generator {
  yield takeLatest(investmentDetailActions.init.type, onInit);
  yield takeLatest(investmentDetailActions.confirmPendingSettlement.type,
    onConfirmPendingSettlement);
  yield takeLatest(investmentDetailActions.submitPartialPayment.type,
    onSubmitPartialPayment);
}
