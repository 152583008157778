import { ISagaModule } from 'redux-dynamic-modules-saga';
import { signInReducer } from './signInPageSlice';
import { SignInPageReduxState } from './signInPageTypes';
import signInPageSage from './signInPageSaga';

export default (): ISagaModule<SignInPageReduxState> => ({
  id: 'signIn',
  reducerMap: {
    signIn: signInReducer,
  },
  sagas: [signInPageSage],
  retained: true,
});
