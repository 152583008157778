import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialLoadingState, LoadingData } from 'common/util/loading';
import emptyFunction from 'common/util/emptyFunction';
import { CloseReportPageState } from './closeReportPageTypes';

const initialState: CloseReportPageState = {
  pageLoading: initialLoadingState,
};

const closeReportSlice = createSlice({
  name: 'closeReport',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: emptyFunction,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    submit: emptyFunction,
    getClosedReport: emptyFunction,
    getPendingSettlementReport: emptyFunction,
    getActiveInvestmentReport: emptyFunction,
  },
});

export const closeReportActions = closeReportSlice.actions;
export const closeReportReducer = closeReportSlice.reducer;
