import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialLoadingState, LoadingData } from 'common/util/loading';
import { SignInForm, SignInPageState } from './signInPageTypes';
import { CreateInvestmentForm } from '../../investment/createInvestment/createInvestmentPageTypes';

const initSignInForm: SignInForm = {
  username: '',
  password: '',
  errorMsg: '',
};

const initialState: SignInPageState = {
  form: initSignInForm,
  formLoading: initialLoadingState,
};

const signInSlice = createSlice({
  name: 'signIn',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },

    setFormLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.formLoading = payload;
      return state;
    },

    setForm(state, { payload }: PayloadAction<SignInForm>) {
      state.form = payload;
      return state;
    },
    setErrorMsg(state, { payload } : PayloadAction<string>) {
      console.log('Inside SetErrorMSG fx');
      console.log(payload);
      state.form.errorMsg = payload;
      return state;
    },
    submit: (state, _action: PayloadAction<SignInForm>) => state,
  },
});

export const signInActions = signInSlice.actions;
export const signInReducer = signInSlice.reducer;
