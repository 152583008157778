import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LoadingSwitch from 'common/ui/LoadingSwitch';

import ContentContainer from 'common/ui/ContentContainer';
import PageTitle from 'common/ui/PageTitle';
import { useHistory } from 'react-router';
import Button from 'common/ui/Button';
import Card from 'common/ui/Card';
import classes from './closeReportPage.module.scss';
import { closeReportActions } from './closeReportPageSlice';
import { CloseReportPageReduxState } from './closeReportPageTypes';

const CloseReportPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // Normally when user visits the page, has to load some data from remote
  // for showing, the `pageLoading` state is used to keep track this.
  const pageLoading = useSelector(
    (s: CloseReportPageReduxState) => s.closeReport.pageLoading,
  );

  // When user visits the page, dispatch an action immediately to inform
  // saga to do page initialisation (mainly to load data needed).
  React.useEffect(() => {
    dispatch(closeReportActions.init());
  }, []);

  return (
    <ContentContainer>
      <PageTitle
        onBack={() => history.goBack()}
      >
        Investment Close Report
      </PageTitle>
      <LoadingSwitch loading={pageLoading}>
        <Card>
          <div className="row">
            <div className="col-12">
              <p>
                By clicking the button below,
                the system will generate an Excel report with the
                summary record of all closed investments
                within RTS to date.
              </p>
              <Button
                label="Generate Full Report"
                className="w-100 "
                onClick={() => dispatch(closeReportActions.submit())}
              />

              <button
                type="button"
                className="btn btn-block mt-4 btn-success"
                onClick={() => dispatch(closeReportActions.getClosedReport())}
              >
                Generate Closed Investment Report
              </button>

              <button
                type="button"
                className="btn btn-block mt-4 btn-info"
                onClick={() => dispatch(closeReportActions.getPendingSettlementReport())}
              >
                Generate Pending Settlement Investment Report
              </button>

              <button
                type="button"
                className="btn btn-block mt-4 btn-primary"
                onClick={() => dispatch(closeReportActions.getActiveInvestmentReport())}
              >
                Generate Active Investment Report
              </button>
            </div>
          </div>
        </Card>
      </LoadingSwitch>
    </ContentContainer>
  );
};

export default CloseReportPage;
