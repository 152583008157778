import { formatDateTime, fromApiDate } from 'common/util/date';
import { DateTime } from 'luxon';
import { after, before } from 'lodash';
import {
  EguyCloseSummary,
  EguyCloseSummaryRemote,
  InvestmentCloseSummary,
  InvestmentCloseSummaryRemote,
} from './closeInvestmentPageTypes';
import { InvestmentDetailFundTransactionRemote } from '../shared/investmentDetailRemote';
import { InvestmentDetailTransaction } from '../detail/investmentDetailPageTypes';

export const mapCloseInvestmentSummaryFromApi = (response: InvestmentCloseSummaryRemote)
  : InvestmentCloseSummary => ({
  id: response.id,
  amount: response.amount,
  companyProfit: response.company_profit,
  customerId: response.customer_id,
  customerName: response.customer_name,
  formNo: response.form_no,
  insertedById: response.insert_by_id,
  investorProfit: response.investor_profit,
  isClean: response.is_clean,
  status: response.status,
  totalProfit: response.total_profit,
  transactionDateTime: formatDateTime(fromApiDate(response.transaction_date_time)),
  updatedByid: response.updated_by_id,
  finalBalance: response.final_balance,
  totalPartialPayout: response.total_partial_payout,
});
export const mapEguySummaryFromApi = (response: EguyCloseSummaryRemote) : EguyCloseSummary => {
  const totalProfit = response.profit_transactions
    .reduce((profit, currentProfit) => currentProfit.amount + profit, 0);
  const companyRate = response.company_take / 100;
  const customerRate = response.customer_take / 100;
  const totalPayout = response.payout_transactions
    .reduce((payouts, currentPayout) => currentPayout.amount + payouts, 0);
  const transaction = response.portfolio_transactions.sort((a, b) => {
    const aDate = fromApiDate(a.date_time);
    const bDate = fromApiDate(b.date_time);
    return aDate.diff(bDate).valueOf();
  }).reduce<InvestmentDetailTransaction[]>(
    (prev, current, currentIndex) => {
      let beforeAmount = 0;
      let afterAmount = 0;
      let percentage : number|string = '';
      let companyLost = 0;
      if (currentIndex === 0) {
        afterAmount += current.amount;
        percentage = 'N/A';
      } else {
        beforeAmount = prev[prev.length - 1].afterAmount;
        afterAmount = beforeAmount + current.amount;
        percentage = current.amount / beforeAmount;
        percentage *= 100;

        if (afterAmount < response.amount) {
          companyLost = response.amount - afterAmount;
          afterAmount += companyLost;
        }
      }
      prev.push({
        id: current.id,
        beforeAmount,
        dateTime: fromApiDate(current.date_time),
        amount: current.amount,
        type: current.type,
        afterAmount,
        percentage,
        companyLost,
      });
      return prev;
    }, [],
  );

  return {
    id: response.id,
    formNo: response.form_no,
    dateTime: response.transaction_datetime,
    customerName: response.customer_name,
    amount: response.amount,
    finalAmount: response.final_amount,
    customerTake: response.customer_take,
    companyTake: response.company_take,
    totalPayout,
    totalProfit,
    fundType: response.fund_type,
    companyProfit: totalProfit * companyRate,
    customerProfit: totalProfit * customerRate,
    profitTransactions: response.profit_transactions,
    payoutTransactions: response.payout_transactions,
    portfolioTransactions: transaction,
    totalDividend: response.total_dividend,
    totalInvestmentProfit: response.total_investment_profit,
    totalManagementFee: response.total_management_fee,
  };
};
