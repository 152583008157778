import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialLoadingState, initialLoadingTrueState, LoadingData } from 'common/util/loading';
import emptyFunction from 'common/util/emptyFunction';
import { CustomerLoginForm, CustomerPortalPageState, CustomerPorterInvestment } from './customerPortalPageTypes';
import { InvestmentStatus } from '../investment/shared/investmentStatus';

const initialState: CustomerPortalPageState = {
  pageLoading: initialLoadingTrueState,
  loginLoading: initialLoadingState,
  detail: {
    id: 0,
    amount: 0,
    bonusPayoutRate: 0,
    customer: {
      address: '',
      contactNumber: '',
      idNumber: '',
      name: '',
    },
    formNo: '',
    future: {
      balanceAmount: 0,
      initialAmount: 0,
      settlements: [],
      transactions: [],
    },
    maturityDate: '',
    spotBalance: {
      unrealizedProfitLoss: 0,
      totalMarketValue: 0,
      data: [],
    },
    status: InvestmentStatus.Active,
  },
};

const customerPortalSlice = createSlice({
  name: 'customerPortal',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: emptyFunction,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    setLoginLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.loginLoading = payload;
      return state;
    },
    setDetail(state, { payload }: PayloadAction<CustomerPorterInvestment>) {
      state.detail = payload;
      return state;
    },
    submitLoginForm: (state, _action: PayloadAction<CustomerLoginForm>) => state,
  },
});

export const customerPortalActions = customerPortalSlice.actions;
export const customerPortalReducer = customerPortalSlice.reducer;
