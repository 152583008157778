import { formatCurrency } from 'common/util/currency';
import React from 'react';
import Card from 'common/ui/Card';
import { toApiDate } from 'common/util/date';
import { InvestmentDetailTransaction } from '../../investmentDetailPageTypes';
import { InvestmentTransactionType } from '../../../shared/investmentTransactionType';

interface CPMTable{
  customerRate: number,
  companyRate: number,
  transactions: InvestmentDetailTransaction[]
}

interface profitBreakdown {
  date: string | undefined,
  currentProfit: number,
  accumulatedProfit: number,
  companyTake: number,
  customerTake: number,
  accumulatedCompanyTake: number,
  accumulatedCustomerTake: number,
}
const CPMTable: React.FC<CPMTable> = (detail) => {
  const { customerRate, companyRate, transactions } = detail;
  const customerTake = customerRate * 100;
  const companyTake = companyRate * 100;

  const profitBreakdown = transactions
    .filter((item) => item.type === InvestmentTransactionType.Settlements)
    .reduce<profitBreakdown[]>((prev, current, index) => {
      const currentProfit = current.amount;
      let accumulated_profit = current.amount;
      if (prev.length > 0) {
        accumulated_profit = prev[prev.length - 1].accumulatedProfit + current.amount;
      }

      prev.push({
        date: toApiDate(current.dateTime),
        currentProfit,
        accumulatedProfit: accumulated_profit,
        companyTake: currentProfit * companyRate,
        customerTake: currentProfit * customerRate,
        accumulatedCompanyTake: accumulated_profit * companyRate,
        accumulatedCustomerTake: accumulated_profit * customerRate,
      });
      return prev;
    }, []);
  return (
    <>
      <Card title="Profit Breakdown">
        <table className="table">
          <thead>
            <tr>
              <th className="text-center" rowSpan={2}>Date</th>
              <th className="text-center" rowSpan={2}>
                Amount
                <br />
                (USDT)
              </th>
              <th className="text-center" rowSpan={2}>
                Investor Share
                <br />
                (
                {customerTake}
                %)
              </th>
              <th className="text-center" rowSpan={2}>
                Company Share
                <br />
                (
                {companyTake}
                %)
              </th>
              <th className="text-center" colSpan={3}>Accumulated Profit (USDT)</th>
            </tr>
            <tr>
              <th>Total</th>
              <th>
                Investor
                {' '}
                Share
              </th>
              <th>Company Share</th>
            </tr>
          </thead>
          <tbody>
            {
                profitBreakdown.map((value, index) => (
                  <tr>
                    <td>{value.date}</td>
                    <td>{formatCurrency(value.currentProfit, 'two')}</td>
                    <td>{formatCurrency(value.customerTake, 'two')}</td>
                    <td>{formatCurrency(value.companyTake, 'two')}</td>
                    <td className={index === profitBreakdown.length - 1 ? 'font-weight-bold' : ''}>{formatCurrency(value.accumulatedProfit, 'two')}</td>
                    <td className={index === profitBreakdown.length - 1 ? 'font-weight-bold' : ''}>{formatCurrency(value.accumulatedCustomerTake, 'two')}</td>
                    <td className={index === profitBreakdown.length - 1 ? 'font-weight-bold' : ''}>{formatCurrency(value.accumulatedCompanyTake, 'two')}</td>
                  </tr>
                ))
            }
          </tbody>
        </table>
      </Card>
    </>
  );
};

export default CPMTable;
