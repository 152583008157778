import {
  delay,
  put, takeLatest,
} from 'redux-saga/effects';
import { bindLoadingActions } from 'common/util/loading';
import { call, SagaGenerator, select } from 'typed-redux-saga';
import toastActions from 'common/ui/Toast/toastActions';
import {
  List, RemoteList, mapRemoteList, getListRequestQueryString, PageScrollDirection,
} from 'common/util/list';
import ApiService from 'common/api';
import { Action } from 'redux';
import { fromApiDate, formatDate } from 'common/util/date';
import { InvestmentListItem, InvestmentListItemRemote, InvestmentListPageReduxState } from './investmentListPageTypes';
import { investmentListActions } from './investmentListPageSlice';

function* loadList(direction: PageScrollDirection): SagaGenerator<List<InvestmentListItem>> {
  const search = yield* select((s: InvestmentListPageReduxState) => s.investmentList.search);
  const list = yield* select((s: InvestmentListPageReduxState) => s.investmentList.list);
  const queryString = getListRequestQueryString(list.info, direction);

  const resp = yield* call(ApiService.get<RemoteList<InvestmentListItemRemote>>(), `/v1/investments?keyword=${search}&${queryString}`);

  const result = mapRemoteList(
    resp,
    (remote) => remote.map<InvestmentListItem>((l) => ({
      id: l.id,
      formNo: l.form_no,
      amount: l.amount,
      fundType: l.fund_type,
      customerName: l.customer_name,
      referralName: l.referral_name,
      currentAmount: l.current_amount,
      profit: l.profit,
      profitPercentage: getPercentage(l.amount, l.profit),
      status: l.status,
      maturityDate: formatDate(fromApiDate(l.maturity_date)),
    })),
  );

  return result;
}

function getPercentage(amount: number, profit: number): number {
  const ratio = profit / amount;
  return ratio * 100;
}
function* onInit(): Generator {
  yield put(investmentListActions.setInitialState());

  const [, loadingFail, loadingSuccess] = bindLoadingActions(investmentListActions.setPageLoading);
  try {
    const list = yield* call(loadList, PageScrollDirection.First);
    yield put(investmentListActions.setList(list));
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

function* onSearch(action: Action): Generator {
  if (!investmentListActions.search.match(action)) { return; }

  yield put(investmentListActions.setSearch(action.payload));
  yield delay(500);

  const [loadingStart, loadingFail, loadingSuccess] = bindLoadingActions(
    investmentListActions.setPageLoading,
  );
  yield put(loadingStart());

  try {
    const list = yield* call(loadList, PageScrollDirection.First);
    yield put(investmentListActions.setList(list));
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

function* onPage(action: Action): Generator {
  if (!investmentListActions.page.match(action)) { return; }

  const [loadingStart, loadingFail, loadingSuccess] = bindLoadingActions(
    investmentListActions.setPageLoading,
  );
  yield put(loadingStart());

  try {
    const list = yield* call(loadList, action.payload);
    yield put(investmentListActions.setList(list));
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

function* onDelete(): Generator {
  const [loadingStart, loadingFail, loadingSuccess] = bindLoadingActions(
    investmentListActions.setDeleteLoading,
  );
  const { id } = yield* select((s: InvestmentListPageReduxState) => s.investmentList.deletePrompt);
  yield put(loadingStart());

  try {
    yield* call(ApiService.delete(), `/v1/investments/${id}`);

    const list = yield* call(loadList, PageScrollDirection.Stay);
    yield put(investmentListActions.setList(list));

    toastActions.success('The investment is deleted successfully.');
    yield put(loadingSuccess());
  } catch (e) {
    toastActions.error(e.message);
    yield put(loadingFail(e.message));
  }
}

export default function* mainSaga(): Generator {
  yield takeLatest(investmentListActions.init.type, onInit);
  yield takeLatest(investmentListActions.search.type, onSearch);
  yield takeLatest(investmentListActions.page.type, onPage);
  yield takeLatest(investmentListActions.confirmDelete.type, onDelete);
}
