import { fromApiDate } from 'common/util/date';
import { investmentStatusLookup } from '../../investment/shared/investmentStatus';
import { InvestmentCloseListItem, InvestmentCloseListItemRemote } from './closeReportPageTypes';

const convertDate = (remoteDate: string): Date => {
  // Date workaround as the exceljs read every date as UTC
  const luxonDate = fromApiDate(remoteDate);
  return luxonDate.plus({ minutes: luxonDate.offset }).toJSDate();
};

// eslint-disable-next-line import/prefer-default-export
export const mapInvestmentCloseListItemFromApi = (response: InvestmentCloseListItemRemote)
  : InvestmentCloseListItem => ({
  id: response.id,
  amount: response.amount,
  isClean: response.is_clean,
  statusRaw: response.status,
  status: investmentStatusLookup[response.status].label,
  companyProfit: (response.total_profit - response.total_partial_payout) > 0
    ? response.company_profit : response.total_profit - response.total_partial_payout,
  companyTake: response.company_take,
  customerTake: response.customer_take,
  customerName: response.customer_name,
  totalProfit: response.total_profit,
  fundType: response.fund_type,
  totalPartialPayout: response.total_partial_payout,
  netProfit: response.total_profit - response.total_partial_payout,
  djProfit: response.dj_profit,
  formNo: response.form_no,
  investorProfit: (response.total_profit - response.total_partial_payout) > 0
    ? response.investor_profit : 0,
  maturityDate: convertDate(response.maturity_date),
  transactionDateTime: convertDate(response.transaction_date_time),
  closeAmount: response.close_amount,
  closeDate: response.close_date
    ? convertDate(response.close_date)
    : null,
  referralProfit: response.referral_profit,
});
