import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialLoadingState, initialLoadingTrueState, LoadingData } from 'common/util/loading';
import { DateTime } from 'luxon';
import { InvestmentStatus } from '../shared/investmentStatus';
import { ReferralPayoutMode } from '../shared/referralPayoutMode';
import { CloseInvestmentForm, CloseInvestmentPageState, InvestmentCloseSummary } from './closeInvestmentPageTypes';

const initialState: CloseInvestmentPageState = {
  pageLoading: initialLoadingTrueState,
  formLoading: initialLoadingState,
  detail: {
    id: 0,
    amount: 0,
    companyProfit: 0,
    customerId: 0,
    customerName: '',
    formNo: '',
    insertedById: 0,
    investorProfit: 0,
    isClean: false,
    status: InvestmentStatus.Active,
    totalProfit: 0,
    transactionDateTime: '',
    updatedByid: 0,
    totalPartialPayout: 0,
    finalBalance: 0,
  },
};

const closeInvestmentSlice = createSlice({
  name: 'closeInvestment',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: (state, _state: PayloadAction<number>) => state,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    setFormLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.formLoading = payload;
      return state;
    },
    setDetail(state, { payload }: PayloadAction<InvestmentCloseSummary>) {
      state.detail = payload;
      return state;
    },
    submit: (state, _action: PayloadAction<CloseInvestmentForm>) => state,
  },
});

export const closeInvestmentActions = closeInvestmentSlice.actions;
export const closeInvestmentReducer = closeInvestmentSlice.reducer;
