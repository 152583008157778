import * as React from 'react';
import { useSelector } from 'react-redux';

import ContentContainer from 'common/ui/ContentContainer';
import PageTitle from 'common/ui/PageTitle';
import Card from 'common/ui/Card';
import LabelValueRow from 'common/ui/LabelValueRow';
import { formatCurrency } from 'common/util/currency';
import { CardSummaryCurrencyBlock, CardSummaryPercentBlock } from 'common/ui/CardSummaryBlock';
import { formatDate, formatDateTime } from 'common/util/date';
import ReactECharts from 'echarts-for-react';
import appConfig from 'common/setup/config';
import getProfiltSharingRatioText from '../investment/shared/getProfiltSharingRatioText';
import { InvestmentStatus } from '../investment/shared/investmentStatus';
import { CustomerPortalPageReduxState } from './customerPortalPageTypes';

const CustomerLogin: React.FC = () => {
  const detail = useSelector(
    (s: CustomerPortalPageReduxState) => s.customerPortal.detail,
  );

  const chartData = React.useMemo(() => detail.spotBalance.data.map((d) => ({
    name: d.name, value: d.total,
  })), []);

  return (
    <div className="container">
      <ContentContainer>
        <PageTitle>
          Welcome!
        </PageTitle>
        <p>The following the summary of your current investment portfolio.</p>
        <Card
          title="Profile"
        >
          <div className="row">
            <div className="col-md-6">
              <LabelValueRow label="Name">
                {detail.customer.name}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              <LabelValueRow label="ID Number">
                {detail.customer.idNumber}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              <LabelValueRow label="Contact Number">
                {detail.customer.contactNumber}
              </LabelValueRow>
            </div>
            <div className="col-12">
              <LabelValueRow label="Address">
                <div className="multiline">
                  {detail.customer.address}
                </div>
              </LabelValueRow>
            </div>
          </div>
        </Card>
        <div className="my-3" />
        <Card title="Investment">
          <div className="row">
            <div className="col-md-6">
              <LabelValueRow label="Form No">
                {detail.formNo}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              <LabelValueRow label="Amount">
                {`${formatCurrency(detail.amount, 'two')} USDT`}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              <LabelValueRow label="Maturity Date">
                {detail.maturityDate}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              {appConfig.appVersion < 2 && (
                <LabelValueRow label="Bonus Payout">
                  {`${detail.bonusPayoutRate}%`}
                </LabelValueRow>
              )}
            </div>
          </div>
        </Card>
        <div className="my-3" />
        {(detail.status === InvestmentStatus.Closed && detail.closeSummary) ? (
          <Card
            title="Closing Summary"
          >
            <table className="table table-hover">
              <tbody>
                {detail.closeSummary.totalPartialPayout && (
                  <tr>
                    <td>Total Previous Payout</td>
                    <td>{`${formatCurrency(detail.closeSummary.totalPartialPayout, 'two')} USDT`}</td>
                  </tr>
                )}

                <tr>
                  <td>Final Spot Value</td>
                  <td>{`${formatCurrency(detail.closeSummary.spotValue, 'two')} USDT`}</td>
                </tr>
                <tr>
                  <td>Final Spot ROI</td>
                  <td>{`${formatCurrency(detail.closeSummary.spotRoi, 'two')}%`}</td>
                </tr>
                <tr>
                  <td>Final Future Value</td>
                  <td>{`${formatCurrency(detail.closeSummary.futureValue, 'two')} USDT`}</td>
                </tr>
                <tr>
                  <td>---</td>
                  <td />
                </tr>
                <tr>
                  <td>Total Profit</td>
                  <td>{`${formatCurrency(detail.closeSummary.totalProfit, 'two')} USDT`}</td>
                </tr>
                <tr>
                  <td>{`Sharing Profit ${getProfiltSharingRatioText('investor')}`}</td>
                  <td>{`${formatCurrency(detail.closeSummary.fairProfit, 'two')} USDT`}</td>
                </tr>
                {detail.bonusPayoutRate > 0 && (
                <tr>
                  <td>{`Bonus Payout ${detail.bonusPayoutRate}%`}</td>
                  <td>{`${formatCurrency(detail.closeSummary.bonusPayout, 'two')} USDT`}</td>
                </tr>
                )}
                <tr>
                  <td>Total Profit</td>
                  <td><strong>{`${formatCurrency(detail.closeSummary.investorProfit, 'two')} USDT`}</strong></td>
                </tr>
              </tbody>
            </table>
          </Card>
        ) : (
          <>
            <Card title="Spot Account Portfolio">
              <div className="row">
                <div className="col-md-6">
                  <CardSummaryCurrencyBlock title="Current Account Value" value={detail.spotBalance.totalMarketValue} />
                </div>
                <div className="col-md-6">
                  <CardSummaryPercentBlock title="Unrealized Profit/Loss" value={detail.spotBalance.unrealizedProfitLoss} />
                </div>
              </div>
              <div className="my-3" />
              <table className="table table-hover table-responsive-md">
                <thead>
                  <tr>
                    <th scope="col">Currency</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Current Market Price</th>
                    <th scope="col">Holding Total</th>
                  </tr>
                </thead>
                <tbody>
                  {detail.spotBalance.data.map((i) => (
                    <tr key={i.name}>
                      <td>{i.name}</td>
                      <td>{formatCurrency(i.balance, i.name === 'USDT' ? 'two' : 'six')}</td>
                      <td>{formatCurrency(i.price, 'two')}</td>
                      <td>{formatCurrency(i.total, 'two')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="my-3" />
              <ReactECharts option={{
                title: {
                  text: 'Spot Account Portfolio',
                  left: 'center',
                },
                tooltip: {
                  trigger: 'item',
                  formatter: ({
                    name, value, percent, color,
                  }: {
                name: string,
                value: number,
                percent: number,
                color: string,
              }) => `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${color}"></span> ${name}: <b>${formatCurrency(value, 'three')}</b> (${percent}%)`,
                },
                legend: {
                  orient: 'vertical',
                  left: 'left',
                },
                series: [
                  {
                    type: 'pie',
                    radius: '50%',
                    data: chartData,
                    emphasis: {
                      itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                      },
                    },
                  },
                ],
              }}
              />
            </Card>
            <div className="my-3" />
            <Card title="Future Account Portfolio">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Settlement Date</th>
                    <th scope="col">Before Balance</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Profit/Loss %</th>
                    <th scope="col">After Balance</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Initial Balance:</td>
                    <td>{formatCurrency(detail.future.initialAmount, 'two')}</td>
                    <td />
                    <td />
                  </tr>
                  {detail.future.settlements.map((i) => (
                    <tr key={i.id}>
                      <td>{formatDateTime(i.dateTime)}</td>
                      <td>{formatCurrency(i.beforeSettlementAmount, 'two')}</td>
                      <td className={i.percent > 0 ? 'text-success' : 'text-danger'}>
                        {formatCurrency(i.settlementAmount, 'four')}
                        {i.toSpot > 0 && (
                        <div>
                          <small className="font-italic text-black-50">
                            To
                            Spot:
                            {formatCurrency(i.toSpot, 'four')}
                          </small>
                        </div>
                        )}
                      </td>
                      <td
                        className={i.percent > 0 ? 'text-success' : 'text-danger'}
                      >
                        {formatCurrency(i.percent, 'two')}
                      </td>
                      <td>{formatCurrency(i.afterSettlementAmount, 'two')}</td>
                      {/* <td>{formatCurrency(i.toSpot, 'two')}</td> */}
                    </tr>
                  ))}
                  <tr>
                    <td>Current Balance:</td>
                    <td>{formatCurrency(detail.future.balanceAmount, 'two')}</td>
                    <td />
                    <td>Current Total To Spot:</td>
                    <td>
                      {formatCurrency(detail.future.settlements.reduce(
                        (item, current) => item + current.toSpot, 0,
                      ), 'two')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </>
        )}

      </ContentContainer>
    </div>
  );
};

export default CustomerLogin;
