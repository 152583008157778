import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  IssueClosedIcon, IssueOpenedIcon, PencilIcon, FoldUpIcon, ChevronRightIcon,
} from '@primer/octicons-react';

import LoadingSwitch from 'common/ui/LoadingSwitch';

import ContentContainer from 'common/ui/ContentContainer';
import PageTitle from 'common/ui/PageTitle';
import { useHistory, useParams } from 'react-router';
import Card from 'common/ui/Card';
import { formatCurrency } from 'common/util/currency';
import { formatDate, formatDateTime } from 'common/util/date';
import { CardSummaryCurrencyBlock, CardSummaryPercentBlock } from 'common/ui/CardSummaryBlock';
import { Link } from 'react-router-dom';
import { IconButton, LinkButton } from 'common/ui/Button';
import toastActions from 'common/ui/Toast/toastActions';
import { investmentDetailActions } from './investmentDetailPageSlice';
import { InvestmentDetailPageReduxState } from './investmentDetailPageTypes';
import LabelValueRow from '../../common/ui/LabelValueRow';
import { investmentTransactionTypeLookup } from '../shared/investmentTransactionType';
import { InvestmentStatus, investmentStatusLookup } from '../shared/investmentStatus';
import InvestmentPendingSettlementModal from './investmentPendingSettlementModal';
import PartialPayoutModal from './PartialPayoutModal';
import DividendPayoutModal from './DividendPayoutModal';
import SettlementInfo from './component/SettlementInfo';
import DividendPayoutInfo from './DividendPayoutInfo';
import ProfitPayoutInfo from './ProfitPayoutInfo';
import CPMTable from './component/profitBreakdownTable/CPMTable';

const InvestmentDetailPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  // Normally when user visits the page, has to load some data from remote
  // for showing, the `pageLoading` state is used to keep track this.
  const pageLoading = useSelector(
    (s: InvestmentDetailPageReduxState) => s.investmentDetail.pageLoading,
  );
  const detail = useSelector(
    (s: InvestmentDetailPageReduxState) => s.investmentDetail.detail,
  );
  const spotBalance = useSelector(
    (s: InvestmentDetailPageReduxState) => s.investmentDetail.spotBalance,
  );

  const settlement_date = detail.settlement?.date !== undefined ? detail.settlement?.date : '';

  const payoutInfo = detail.transactions
    .filter((item) => item.type === 'partial_payout');

  // When user visits the page, dispatch an action immediately to inform
  // saga to do page initialisation (mainly to load data needed).
  React.useEffect(() => {
    const idNumber = parseInt(id, 10);
    dispatch(investmentDetailActions.init(idNumber));
  }, [id]);

  let partialPayable = false;

  if (detail.fundType === 'cpm') {
    partialPayable = Number(detail.currentAmount) <= Number(detail.originalAmount);
  }

  const realDividendRate = detail.annualPayoutPerc / 200;
  const dividend = detail.amount * realDividendRate;
  const totalDividend = dividend * payoutInfo.length;

  const realMgmtRate = detail.mgmtFeePerc / 200;
  const mgmtFee = detail.amount * realMgmtRate;
  const totalManagementFee = mgmtFee * payoutInfo.length;
  const settlementDetails = { ...detail, totalManagementFee, totalDividend };
  return (
    <ContentContainer>
      <InvestmentPendingSettlementModal />
      <DividendPayoutModal />
      <PartialPayoutModal />
      <PageTitle
        onBack={() => history.goBack()}
        buttons={[detail.id > 0 && detail.status !== InvestmentStatus.Closed && {
          label: 'Edit',
          icon: PencilIcon,
          isDisabled: false,
          onClick: () => history.push(`/member/investment/edit/${detail.id}`),
        }, detail.id > 0 && detail.status === InvestmentStatus.Active && detail.fundType !== 'eps' && {
          label: 'Partial Payout',
          icon: FoldUpIcon,
          isDisabled: partialPayable,
          onClick: () => dispatch(
            investmentDetailActions.setPartialPaymentPrompt(
              {
                id: detail.id,
                name: detail.formNo,
                amount: detail.currentAmount,
                originalAmount: detail.originalAmount,
                fundType: detail.fundType,
              },
            ),
          ),
        },
        detail.id > 0 && detail.status === InvestmentStatus.Active && {
          label: 'To Pending Full Settlement',
          icon: IssueOpenedIcon,
          isDisabled: false,
          onClick: () => dispatch(
            investmentDetailActions.setPendingSettlementPrompt(
              { id: detail.id, name: detail.formNo },
            ),
          ),
        }, detail.id > 0 && detail.status === InvestmentStatus.PendingSettlement && {
          label: 'Close',
          icon: IssueClosedIcon,
          isDisabled: false,
          onClick: () => history.push(`/member/investment/close/${detail.id}`),
        }]}
      >
        {detail.formNo ? detail.formNo : 'Investment'}
      </PageTitle>
      <LoadingSwitch loading={pageLoading}>
        <Card
          title="Customer"
          titleRight={(
            <IconButton
              icon={PencilIcon}
              onClick={() => history.push(`/member/customer/edit/${detail.customerId}`)}
            />
              )}
        >
          <div className="row">
            <div className="col-md-6">
              <LabelValueRow label="Name">
                {detail.customer.name}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              <LabelValueRow label="ID Number">
                {detail.customer.idNumber}
              </LabelValueRow>
            </div>
            {
                  detail.fundType !== 'eps' && (
                  <>
                    <div className="col-md-6">
                      <LabelValueRow label="Contact Number">
                        {detail.customer.contactNumber}
                      </LabelValueRow>
                    </div>
                    <div className="col-12">
                      <LabelValueRow label="Address">
                        <div className="multiline">
                          {detail.customer.address}
                        </div>
                      </LabelValueRow>
                    </div>
                  </>
                  )
              }
          </div>
        </Card>
        <div className="my-3" />
        <Card title={`Investment (Type: ${detail.fundType?.toUpperCase()})`}>
          <div className="row">
            <div className="col-md-6">
              <LabelValueRow label="Form No">
                {detail.formNo}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              <LabelValueRow label="Invested Amount">
                {`${formatCurrency(detail.amount, 'two')} USDT`}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              <LabelValueRow label="Transaction Date">
                {detail.transactionDateTime}
              </LabelValueRow>
            </div>
            <div className="col-12">
              <LabelValueRow label="Status">
                {investmentStatusLookup[detail.status].label}
              </LabelValueRow>
            </div>
            {
                  detail.fundType === 'fpm' && (
                  <>
                    <div className="col-6">
                      <LabelValueRow label="Annual Payout %">
                        {`${formatCurrency(detail.annualPayoutPerc ?? 0, 'two')} %`}
                      </LabelValueRow>
                    </div>
                    <div className="col-6">
                      <LabelValueRow label="Management Fee %">
                        {`${formatCurrency(detail.mgmtFeePerc ?? 0, 'two')} %`}
                      </LabelValueRow>
                    </div>
                  </>
                  )
              }
          </div>
          {
                detail.fundType !== 'eps' && (
                <>
                  <hr className="my-4" />
                  <div className="row">
                    <div className="col-md-6">
                      <LabelValueRow
                        label={detail.fundType === 'fpm' ? 'Associate Profit Take' : 'Customer Profit Take'}
                      >
                        {detail.customerTake}
                        {' '}
                        %
                      </LabelValueRow>
                    </div>
                    <div className="col-md-6">
                      <LabelValueRow label="Company Profit Take">
                        {detail.companyTake}
                        {' '}
                        %
                      </LabelValueRow>
                    </div>
                  </div>
                </>
                )
            }
        </Card>
        <div className="my-3" />
        {detail.status === InvestmentStatus.Closed && detail.settlement && (
          <>
            <SettlementInfo detail={settlementDetails} />
          </>
        )}
        {
              detail.fundType === 'cpm' && detail.status !== InvestmentStatus.Closed && (
              <CPMTable
                transactions={detail.transactions}
                companyRate={detail.companyTake / 100}
                customerRate={detail.customerTake / 100}
              />
              )
          }
        {
              detail.fundType === 'fpm' && detail.status !== InvestmentStatus.Closed && (
              <DividendPayoutInfo
                payoutInfo={payoutInfo}
                companyRate={detail.companyTake / 100}
                customerRate={detail.customerTake / 100}
                dividend={dividend}
                mgmtFee={mgmtFee}
              />
              )
          }
        <div className="my-3" />
        <div className="my-3" />
        <Card title="Transactions">
          <table className="table table-hover table-responsive-md">
            <thead>
              <tr>
                <th scope="col">Transaction Date</th>
                <th scope="col">Type</th>
                <th>Before Amount</th>
                <th scope="col">Amount</th>
                <th>After Amount</th>
              </tr>
            </thead>
            <tbody>
              {detail.transactions.map((i) => (
                <tr key={i.id} className={i.amount > 0 ? 'text-success' : 'text-danger'}>
                  <td>{formatDateTime(i.dateTime)}</td>
                  <td>{investmentTransactionTypeLookup[i.type]?.label}</td>
                  <td>{formatCurrency(i.beforeAmount, 'two')}</td>
                  <td>
                    {formatCurrency(i.amount, 'two')}
                    {i.amount >= 0 && (
                      <small>
                        {' '}
                        (+
                        {formatCurrency(i.percentage, 'two')}
                        %)
                      </small>
                    )}
                    {i.amount < 0 && (
                      <small>
                        {' '}
                        (
                        {formatCurrency(i.percentage, 'two')}
                        %)
                      </small>
                    )}

                    {i.companyLost > 0 && (
                      <div className="text-muted">
                        <ChevronRightIcon />
                        <small className="font-italic">{formatCurrency(i.companyLost, 'two')}</small>
                      </div>
                    )}
                  </td>
                  <td>{formatCurrency(i.afterAmount, 'two')}</td>
                </tr>
              ))}
              {
                  detail.status === InvestmentStatus.Closed
                  && (
                  <tr className="text-info">
                    <td>{settlement_date}</td>
                    <td>Close Account</td>
                    <td>{formatCurrency(detail.settlement?.amount ?? 0, 'two')}</td>
                    <td>
                      {' '}
                      -
                      {formatCurrency(detail.settlement?.amount ?? 0, 'two')}
                    </td>
                    <td>0.00</td>
                  </tr>
                  )
              }
            </tbody>
          </table>
        </Card>
        <div className="my-3" />

        <div className="my-3" />
        {detail.childInvestments.length > 0 && (
          <>
            <div className="my-3" />
            <Card title="Referral/Next Accounts">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Form No</th>
                    <th scope="col">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {detail.childInvestments.map((i) => (
                    <tr key={i.id}>
                      <td>
                        <Link to={`/member/investment/detail/${i.id}`}>
                          {i.formNo}
                        </Link>
                      </td>
                      <td>{formatCurrency(i.amount, 'two')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card>
          </>
        )}
      </LoadingSwitch>
    </ContentContainer>
  );
};

export default InvestmentDetailPage;
