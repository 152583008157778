import {
  put, takeLatest,
} from 'redux-saga/effects';
import { bindLoadingActions } from 'common/util/loading';
import ApiService from 'common/api';
import { call } from 'typed-redux-saga';
import { settlementsActions } from './settlementsPageSlice';
import { SettlementInfoApi, SettlementsInfo } from './settlementPageTypes';
import { mapFutureTransactionsFromApi } from './settlementPageMapper';

function* onInit(): Generator {
  yield put(settlementsActions.setInitialState());

  const [, loadingFail, loadingSuccess] = bindLoadingActions(settlementsActions.setPageLoading);
  try {
    const resp = yield* call(ApiService.get<SettlementInfoApi>(), '/v1/quant-settlements');
    const mapped = mapFutureTransactionsFromApi(resp);

    yield put(settlementsActions.setDetail(mapped));
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

export default function* mainSaga(): Generator {
  yield takeLatest(settlementsActions.init.type, onInit);
}
