import React from 'react';
import { toApiDate } from 'common/util/date';
import { Tooltip } from 'react-tooltip';
import { Alert, AlertType } from 'common/ui/Alert';
import Card from 'common/ui/Card';
import { QuestionIcon } from '@primer/octicons-react';
import { formatCurrency } from 'common/util/currency';
import { InvestmentDetailTransaction } from '../investmentDetailPageTypes';
import './index.css';
import { InvestmentTransactionType } from '../../shared/investmentTransactionType';
import CpmSummaries from './summaries/CpmSummaries';
import FpmSummaries from './summaries/FpmSummaries';
import DividendPayoutInfo from '../DividendPayoutInfo';

type Props = {
  detail: {
    companyTake : number,
    customerTake : number,
    transactions: InvestmentDetailTransaction[],
    totalPartialPayout : number,
    amount: number,
    currentAmount: number,
    fundType: string,
    totalDividend: number,
    totalManagementFee: number,
  }
}

interface profitBreakdown {
  date: string | undefined,
  currentProfit: number,
  accumulatedProfit: number,
  companyTake: number,
  customerTake: number,
  accumulatedCompanyTake: number,
  accumulatedCustomerTake: number,
}

const SettlementInfo: React.FC<Props> = ({ detail }) => {
  const companyRate = detail.companyTake / 100;
  const customerRate = detail.customerTake / 100;
  const totalProfit = detail.transactions
    .filter((item) => item.type === InvestmentTransactionType.Settlements)
    .reduce((prev, current) => prev + current.amount, 0);

  const { totalDividend } = detail;
  let netProfit = 0;
  if (detail.fundType === 'cpm') {
    netProfit = totalProfit;
  } else {
    netProfit = totalProfit + totalDividend;
  }
  const companyProfit = netProfit > 0 ? netProfit * companyRate : netProfit;
  const customerProfit = netProfit > 0 ? netProfit * customerRate : 0;

  const epsManagementFee = detail.amount * 0.02;
  const epsCustomerPayable = detail.amount * 1.1;
  const staffPayable = detail.currentAmount - epsManagementFee - epsCustomerPayable;

  const payoutInfo = detail.transactions
    .filter((item) => item.type === InvestmentTransactionType.PartialPayout);

  const profitBreakdown = detail.transactions
    .filter((item) => item.type === InvestmentTransactionType.Settlements)
    .reduce<profitBreakdown[]>((prev, current, index) => {
      const currentProfit = current.amount;
      let accumulated_profit = current.amount;
      if (detail.fundType === 'cpm') {
        if (prev.length > 0) {
          accumulated_profit = prev[prev.length - 1].accumulatedProfit + current.amount;
        }
      } else {
        // If it is FPM, you will need the know the management fees and divideneds
      }
      prev.push({
        date: toApiDate(current.dateTime),
        currentProfit,
        accumulatedProfit: accumulated_profit,
        companyTake: currentProfit * companyRate,
        customerTake: currentProfit * customerRate,
        accumulatedCompanyTake: accumulated_profit * companyRate,
        accumulatedCustomerTake: accumulated_profit * customerRate,
      });
      return prev;
    }, []);

  return (
    <>
      {
        detail.fundType === 'cpm' && (
        <CpmSummaries
          amount={detail.amount}
          totalProfit={totalProfit}
          totalEarlyPayout={detail.totalPartialPayout}
          companyTake={detail.companyTake}
          customerTake={detail.customerTake}
          closingAmount={detail.currentAmount}
          transactions={detail.transactions}
        />

        )
      }
      {
        detail.fundType === 'fpm' && (
        <>
          <FpmSummaries
            amount={detail.amount}
            totalDividend={detail.totalDividend}
            totalManagementFee={detail.totalManagementFee}
            totalProfit={totalProfit}
            customerTake={detail.customerTake}
            companyTake={detail.companyTake}
            payoutInfo={payoutInfo}
          />
          <div className="mb-4" />
          <DividendPayoutInfo
            payoutInfo={payoutInfo}
            companyRate={detail.companyTake / 100}
            customerRate={detail.customerTake / 100}
            dividend={detail.totalDividend / payoutInfo.length}
            mgmtFee={detail.totalManagementFee / payoutInfo.length}
          />
        </>
        )
      }

      {
        detail.fundType === 'eps' && (
        <>
          <Card title="Payable Infomations">
            <div className="row">
              <div className="col-4 mb-4 text-center">
                <p>Closing Balance</p>
                <h5>
                  {formatCurrency(detail.amount, 'two')}
                  {' '}
                  <small>USDT</small>
                </h5>
              </div>
              <div className="col-4 mb-4 text-center">
                <p>Closing Profit</p>
                <h5>
                  {formatCurrency(netProfit, 'two')}
                  {' '}
                  <small>USDT</small>
                </h5>
              </div>
              <div className="col-4 mb-4 text-center">
                <p>Management Fees</p>
                <h5>
                  {formatCurrency(epsManagementFee, 'two')}
                  {' '}
                  <small>USDT</small>
                </h5>
              </div>
              <div className="col-6">
                <Alert type={AlertType.success}>
                  <div className="text-center py-2">
                    <p>
                      Final Payable to Customer
                      <span
                        className="pl-2"
                        data-tooltip-id="info-tooltips"
                        data-tooltip-content="Formula: Initial Amount * 1.1"
                        data-tooltip-place="top"
                      >
                        <QuestionIcon />
                      </span>
                    </p>
                    <h5 className="text-dark">
                      {formatCurrency(epsCustomerPayable, 'two')}
                      {' '}
                      <small>USDT</small>
                    </h5>
                    <small>
                      Please be noted this will be the final amount needed to pay investor.
                      If the figure is incorrect, please find the developer in charge
                    </small>
                  </div>
                </Alert>
              </div>
              <div className="col-6">
                <Alert type={AlertType.success}>
                  <div className="text-center py-2">
                    <p>
                      Final Payable to Staffs
                      <span
                        className="pl-2"
                        data-tooltip-id="info-tooltips"
                        data-tooltip-content="Formula: (Closing Balance - 2% Capital) - Payable to Customer"
                        data-tooltip-place="top"
                      >
                        <QuestionIcon />
                      </span>
                    </p>
                    {' '}
                    <h5 className="text-dark">
                      {formatCurrency(staffPayable, 'two')}
                      {' '}
                      <small>USDT</small>
                    </h5>
                    <small>
                      Please be noted this will be the final amount needed to pay all staffs.
                      for more details please refer to CRM
                    </small>
                  </div>
                </Alert>
              </div>
            </div>
          </Card>
        </>
        )
      }

      <Tooltip id="formula-tooltip" />
    </>
  );
};

export default SettlementInfo;
