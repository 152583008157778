import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialLoadingTrueState, LoadingData } from 'common/util/loading';
import emptyFunction from 'common/util/emptyFunction';
import { SettlementsInfo, SettlementPageState } from './settlementPageTypes';

const initialState: SettlementPageState = {
  pageLoading: initialLoadingTrueState,
  detail: {
    currentFundPool: 0,
    originalFundPool: 0,
    pnl: 0,
    activeInvestors: 0,
    settlements: [],
  },
};

const settlementsSlice = createSlice({
  name: 'settlementsInfo',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: emptyFunction,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    setDetail(state, { payload }: PayloadAction<SettlementsInfo>) {
      state.detail = payload;
      return state;
    },
  },
});

export const settlementsActions = settlementsSlice.actions;
export const settlementsReducer = settlementsSlice.reducer;
