import { formatDate, fromApiDate } from 'common/util/date';
import { TradePrice } from 'context/trade/tradeSlice';
import { InvestmentStatus } from '../investment/shared/investmentStatus';
import { CustomerPortalInvestmentRemote, CustomerPorterInvestment } from './customerPortalPageTypes';
import { mapInvestmentDetailSpotBalanceFromApi } from '../investment/detail/investmentDetailPageMapper';
import {
  FutureFundTransactions,
  InvestmentDetailFuture,
  InvestmentDetailFutureSettlement,
} from '../investment/detail/investmentDetailPageTypes';
import { InvestmentDetailRemote } from '../investment/shared/investmentDetailRemote';

const mapFuture = (response: InvestmentDetailRemote): InvestmentDetailFuture => {
  const { future_fund_transactions: futureTrx, spot_fund_transactions: spotTrx } = response;
  const depositTrx = response.future_fund_transactions.find((t) => t.type === 'deposit');
  const fundTransactions = response.future_fund_transactions.reduce<FutureFundTransactions[]>(
    (prev, current) => {
      let percentage = 0;
      let currentAmount;
      let originalAmount;
      let toSpot = 0;
      const settlementId = current.related_to_id;
      if (prev.length === 0) {
        originalAmount = current.amount;
        currentAmount = current.amount;
      } else {
        const prevAmount = prev[prev.length - 1].accountAmount;

        originalAmount = prev[prev.length - 1].originalAmount;
        currentAmount = prev[prev.length - 1].accountAmount + current.amount;
        percentage = (currentAmount - prevAmount) / prevAmount;
        percentage *= 100;
        toSpot = currentAmount > originalAmount ? currentAmount - originalAmount : 0;
      }

      prev.push({
        id: current.id,
        amount: current.amount,
        dateTime: fromApiDate(current.date_time),
        accountAmount: currentAmount,
        originalAmount,
        percent: percentage,
        settlementId,
        toSpot,
      });
      return prev;
    }, [],
  );

  const settlements = response.future_settlements.reduce<InvestmentDetailFutureSettlement[]>(
    (prev, cur) => {
      // Find mapping transaction.
      const trx = futureTrx.find((t) => t.related_to_id === cur.id && t.type === 'future_settlement')
            || spotTrx.find((t) => t.related_to_id === cur.id && t.type === 'future_settlement');
      const originalAmount = depositTrx?.amount || 0;
      const settlementAmount = trx?.amount || 0;

      let beforeSettlementAmount;
      let afterSettlementAmount;
      let toSpot = 0;
      const percent = cur.settlement_percent;

      if (prev.length > 0) {
        const lastRecord = prev[prev.length - 1];
        beforeSettlementAmount = lastRecord.afterSettlementAmount;
      } else {
        beforeSettlementAmount = depositTrx?.amount || 0;
      }
      afterSettlementAmount = beforeSettlementAmount + settlementAmount;
      if (afterSettlementAmount > originalAmount) {
        toSpot = afterSettlementAmount - originalAmount;
        afterSettlementAmount = originalAmount;
      }

      prev.push({
        id: cur.id,
        beforeSettlementAmount,
        afterSettlementAmount,
        dateTime: fromApiDate(cur.date_time),
        percent,
        settlementAmount,
        toSpot,
      });

      return prev;
    }, [],
  );

  return {
    initialAmount: depositTrx?.amount || 0,
    balanceAmount: response.future_fund_balance,
    settlements,
    transactions: fundTransactions,
  };
};

// eslint-disable-next-line import/prefer-default-export
export const mapCustomerPortalInvestmentFromApi = (
  response: CustomerPortalInvestmentRemote,
  prices: Record<string, TradePrice>,
)
    : CustomerPorterInvestment => {
  const result: CustomerPorterInvestment = {
    id: response.id,
    customer: {
      name: response.customer.name,
      address: response.customer.address,
      contactNumber: response.customer.contact_number,
      idNumber: response.customer.id_number,
    },
    status: response.status,
    formNo: response.form_no,
    maturityDate: formatDate(fromApiDate(response.maturity_date)),
    amount: response.amount,
    bonusPayoutRate: response.bonus_payout_rate,
    future: mapFuture(response),
    spotBalance: mapInvestmentDetailSpotBalanceFromApi(response, prices),
  };

  if (result.status === InvestmentStatus.Closed && response.close_summary) {
    result.closeSummary = {
      bonusPayout: response.close_summary.bonus_payout,
      spotValue: response.close_summary.spot_value,
      futureValue: response.close_summary.future_value,
      investorProfit: response.close_summary.investor_profit,
      spotRoi: response.close_summary.spot_roi,
      fairProfit: response.close_summary.fair_profit,
      totalProfit: response.close_summary.total_profit,
      totalPartialPayout: response.close_summary.total_partial_payout,
    };
  }

  return result;
};
