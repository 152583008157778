import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Modal, { ModalPromptButton } from 'common/ui/Modal';
import { FormikDatePicker, FormikForm, FormikNumberField } from 'common/form';
import FormValue from 'common/ui/FormValue';
import { formatCurrency } from 'common/util/currency';
import {
  dateValidator, numberValidator, stringValidator, Validate,
} from 'common/form/validations';
import Big from 'big.js';
import { LoadingAlert } from 'common/ui/Alert';
import { QuestionIcon } from '@primer/octicons-react';
import { DateTime } from 'luxon';
import { investmentDetailActions } from './investmentDetailPageSlice';
import { InvestmentDetailPageReduxState, InvestmentPartialPayoutForm } from './investmentDetailPageTypes';

const initialForm: InvestmentPartialPayoutForm = {
  id: 0,
  amount: null,
  percent: null,
  transactionDateTime: null,
  customerAmount: null,
  companyAmount: null,
};

const PartialPayoutModal: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(
    (s: InvestmentDetailPageReduxState) => s.investmentDetail.partialPaymentLoading,
  );
  const prompt = useSelector(
    (s: InvestmentDetailPageReduxState) => s.investmentDetail.partialPaymentPrompt,
  );
  const fullData = useSelector(
    (s: InvestmentDetailPageReduxState) => s.investmentDetail.detail,
  );

  const payableAmount = useSelector(
    (s: InvestmentDetailPageReduxState) => s.investmentDetail.partialPayoutAmount,
  );
  const { companyTake } = fullData;
  const { customerTake } = fullData;
  const { transactions } = fullData;
  const [promptForm, setPromptForm] = React.useState(initialForm);
  const validateForm: Validate<InvestmentPartialPayoutForm> = ({ form, validator }) => {
    validator
      .validateField(nameof(form.amount),
        stringValidator.required(),
        numberValidator.gt(0),
        numberValidator.lte(payableAmount.toFixed(3)))
      .validateField(nameof(form.percent),
        stringValidator.required(),
        numberValidator.gt(0),
        numberValidator.lte(100))
      .validateField(nameof(form.transactionDateTime), dateValidator.required());

    return validator;
  };

  React.useEffect(() => {
    if (loading.isSuccess) {
      dispatch(investmentDetailActions.setPartialPaymentPrompt());
    }
  }, [loading]);

  const calculatePercent = (amount: number) => new Big(amount)
    .div(payableAmount)
    .mul(100)
    .round(3)
    .toNumber();

  const calculateAmount = (percent: number) => new Big(payableAmount)
    .mul(percent)
    .div(100)
    .round(3)
    .toNumber();

  React.useEffect(() => {
    if (prompt.id) {
      const defaultPercent = 100;
      setPromptForm({
        ...promptForm,
        id: prompt.id,
        amount: calculateAmount(defaultPercent),
        percent: defaultPercent,
        // transactionDateTime: null,
        customerAmount: calculateAmount(customerTake),
        companyAmount: calculateAmount(companyTake),
      });
    }
  }, [prompt]);

  React.useEffect(() => {
    // console.log(payableAmount)
    setPromptForm(
      {
        ...promptForm,
        amount: payableAmount,
        customerAmount: calculateAmount(customerTake),
        companyAmount: calculateAmount(companyTake),
      },
    );
  }, [payableAmount]);

  return (
    <Modal
      size="small"
      isOpen={prompt.id > 0 && prompt.fundType === 'cpm'}
    >
      <h3>Perform Partial Payout</h3>
      <FormikForm
        initialValues={promptForm}
        enableReinitialize
        validate={validateForm}
        onSubmit={(f) => {
          dispatch(investmentDetailActions.submitPartialPayment(f));
          setPromptForm({
            ...promptForm,
            transactionDateTime: null,
          });
        }}
      >
        {({
          values, handleSubmit, setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <LoadingAlert loading={loading} />
            <FormValue
              label="Form No:"
              value={prompt.name}
            />
            <div className="row">
              <div className="col-6">
                <FormValue
                  label="Current Pool Amount:"
                  value={`${formatCurrency(prompt.amount)} USDT`}
                />
              </div>
              <div className="col-6">
                <FormValue
                  label="Original Amount:"
                  value={`${formatCurrency(prompt.originalAmount)} USDT`}
                />
              </div>
            </div>
            <div className="alert alert-success text-center">
              <p>
                Total Payable Amount
                <span
                  className="pl-2"
                  data-tooltip-id="formula-tooltip"
                  data-tooltip-content="Formula: Current Pool Amount - Original Amount"
                  data-tooltip-place="top"
                >
                  <QuestionIcon />
                </span>
              </p>
              <h4 className="">{`${formatCurrency(payableAmount.toFixed(3), 'three')} USDT`}</h4>
            </div>
            <FormikDatePicker
              time
              name={nameof(values.transactionDateTime)}
              label="Transaction Date Time"
              onChange={(selectedDate) => {
                dispatch(investmentDetailActions
                  .setAvailablePayoutByDate({ selectedDate, transactions }));
                setPromptForm({
                  ...promptForm,
                  transactionDateTime: selectedDate,
                });
              }}
            />
            <FormikNumberField
              name={nameof(values.percent)}
              inputPostLabel="%"
              label="Payout Percent"
              decimal={2}
              onBlur={(newValue) => {
                if (newValue) {
                  const amt = calculateAmount(newValue);
                  const newValuePerc = newValue / 100;
                  const custTake = calculateAmount(customerTake * newValuePerc);
                  const compTake = calculateAmount(companyTake * newValuePerc);
                  setFieldValue(nameof(values.amount), amt);
                  setFieldValue(nameof(values.customerAmount), custTake);
                  setFieldValue(nameof(values.companyAmount), compTake);
                }
              }}
            />
            <FormikNumberField
              name={nameof(values.amount)}
              decimal={3}
              inputPostLabel="USDT"
              label="Payout Amount"
              onBlur={(newValue) => {
                if (newValue) {
                  const newValuePerc = calculatePercent(newValue) / 100;
                  const custTake = calculateAmount(customerTake * newValuePerc);
                  const compTake = calculateAmount(companyTake * newValuePerc);
                  setFieldValue(nameof(values.percent), calculatePercent(newValue));
                  setFieldValue(nameof(values.customerAmount), custTake);
                  setFieldValue(nameof(values.companyAmount), compTake);
                }
              }}
            />
            <div className="row">
              <div className="col-6">
                <FormikNumberField
                  name={nameof(values.customerAmount)}
                  decimal={3}
                  disabled
                  inputPostLabel="USDT"
                  label="Investor Amount"
                />
              </div>
              <div className="col-6">
                <FormikNumberField
                  name={nameof(values.companyAmount)}
                  decimal={3}
                  disabled
                  inputPostLabel="USDT"
                  label="Company Amount"
                />
              </div>
            </div>
            <ModalPromptButton
              isLoading={loading.isLoading}
              yesLabel="Confirm"
              noLabel="Cancel"
              onYesClick={handleSubmit}
              onNoClick={() => dispatch(investmentDetailActions.setPartialPaymentPrompt())}
            />
          </form>
        )}
      </FormikForm>
    </Modal>
  );
};

export default PartialPayoutModal;
