import {
  put, takeLatest,
} from 'redux-saga/effects';
import { bindLoadingActions } from 'common/util/loading';
import { Action } from '@reduxjs/toolkit';
import { userActions } from 'context/user/userSlice';
import { call } from 'typed-redux-saga';
import ApiService from 'common/api/ApiService';
import toastActions from 'common/ui/Toast/toastActions';
import history from 'common/setup/history';
import axios from 'axios';
import { createInvestmentActions } from '../../investment/createInvestment/createInvestmentPageSlice';
import { signInActions } from './signInPageSlice';

type LoginInfo = {
  authorised: boolean,
  name: string,
  email: string,
  accessToken: string,
  refreshToken: string,
  message: string,
}
function* onSubmit(action: Action): Generator {
  if (!signInActions.submit.match(action)) { return; }

  const [loadingStart, loadingFail, loadingSuccess] = bindLoadingActions(
    signInActions.setFormLoading,
  );

  try {
    yield put(loadingStart());

    const form = action.payload;
    const result = yield* call(ApiService.post<LoginInfo>(), '/auth/attempt-login', form);
    yield put(loadingSuccess());

    if (result.authorised) {
      console.log('Login SUCCESS');
      yield put(userActions.setLoginUser({
        email: result.email,
        accessToken: result.accessToken,
        refreshToken: result.refreshToken,
        name: result.name,
      }));
      yield put(loadingSuccess(undefined, '/member/dashboard'));
      history.push('/member');
    } else {
      console.log(result.message);
      yield put(signInActions.setErrorMsg(result.message));
      // yield put(loadingSuccess(undefined, `/auth/sign-in?message=${result.message}&from=`));
      // history.push(`/auth/sign-in?message=${result.message}&from=`);
    }
  } catch (e) {
    console.log(e);
    yield put(signInActions.setErrorMsg('System Error, consult devs'));
    yield put(loadingFail(e.message));
  }
}

export default function* mainSaga(): Generator {
  yield takeLatest(signInActions.submit.type, onSubmit);
}
